import { FederalStateHoliday } from '@aposphaere/core-kit'
import { differenceInBusinessDays, isBefore, isSameDay } from 'date-fns'
import { DateType } from '../contexts/dashboardContext'

type Props = {
  dates: DateType
  clustersCount?: number
  futureOfftimesCount: number
  offtimesUntilTodayCount: number
  publicHolidaysData: FederalStateHoliday[]
}

export const useWorkingDays = ({ dates, clustersCount = 1, futureOfftimesCount, offtimesUntilTodayCount, publicHolidaysData }: Props) => {
  const businessDays = isSameDay(dates.startDate, dates.endDate) ? 1 : differenceInBusinessDays(dates.endDate, dates.startDate) + 1
  const businessDaysUntilToday = differenceInBusinessDays(new Date(), dates.startDate) + 1
  const offtimeCount = futureOfftimesCount + offtimesUntilTodayCount
  const totalPublicHolidaysCount = publicHolidaysData.length
  const publicHolidaysCountUntilToday = publicHolidaysData.reduce((acc, curr) => {
    if (curr.date && (isBefore(new Date(curr.date), new Date()) || isSameDay(new Date(curr.date), new Date()))) {
      return acc + 1
    }
    return acc
  }, 0)

  const publicHolidaysCountLeft = totalPublicHolidaysCount - publicHolidaysCountUntilToday
  const daysInFieldLeft = businessDays - businessDaysUntilToday - futureOfftimesCount - publicHolidaysCountLeft

  return {
    totalDaysInField: businessDays - offtimeCount - totalPublicHolidaysCount,
    totalWorkingDays: businessDays - totalPublicHolidaysCount,
    daysInFieldUntilToday: businessDaysUntilToday - offtimesUntilTodayCount - publicHolidaysCountUntilToday,
    daysInFieldLeft,
  }
}

export const calculateWorkingDaysForQuarter = (
  quarterStartDate: Date | number | undefined | string,
  quarterEndDate: Date | number | undefined | string,
  futureOfftimesCount: number,
  untilTodayCountPerQuarter: number,
  publicHolidaysData: FederalStateHoliday[],
) => {
  if (!quarterStartDate || !quarterEndDate) {
    return
  }

  if (typeof quarterStartDate === 'string' || typeof quarterEndDate === 'string') {
    return
  }
  const businessDays = differenceInBusinessDays(quarterEndDate, quarterStartDate)
  const offtimeCount = futureOfftimesCount + untilTodayCountPerQuarter
  const totalPublicHolidaysCount = publicHolidaysData.length

  return businessDays - offtimeCount - totalPublicHolidaysCount
}
